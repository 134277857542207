<template>
	<div class="texture-background-beige">
		<div class="header-image">
			<h1 class="head-image-text">NLSE CONTENT PRIVACY POLICY</h1>
		</div>

		<div class="context-container mt-4">

			<p class="context-desc" 
				v-for="paragraph in main_statement" 
				:key="paragraph.id"
				v-html="insertHTMLContent(paragraph, 'contact us', '/contact')"
			>
			</p>

			<h2 class="context-title">CONSENT</h2>
			<div class="line"></div>
			<p class="context-desc">
				{{ consent }}
			</p>

			<h2 class="context-title">INFORMATION WE COLLECT</h2>
			<div class="line"></div>
			<p class="context-desc" 
				v-for="paragragh in information_we_collect" 
				:key="paragragh.id"
				v-html="insertHTMLContent(paragragh, 'contact us', '/contact')"
			>
			</p>

			<h2 class="context-title">HOW WE USE YOUR INFORMATION</h2>
			<div class="line"></div>
			<p class="context-desc">
				{{ how_we_use_information.statement }}

				<ul class="context-desc ml-4">
					<li v-for="item in how_we_use_information.listItems" :key="item.id">
						{{ item }}
					</li>
				</ul>
			</p>

			<h2 class="context-title">LOG FILES</h2>
			<div class="line"></div>
			<p class="context-desc">
				{{ log_files }}
			</p>

			<h2 class="context-title">COOKIES AND WEB BEACON</h2>
			<div class="line"></div>
			<p class="context-desc" 
				v-for="paragraph in cookies_and_web_beacons" 
				:key="paragraph.id"
				v-html="insertHTMLContent(paragraph, 'What Are Cookies', 'https://www.privacypolicyonline.com/what-are-cookies', '_blank')"
			>
			</p>

			<h2 class="context-title">ADVERTISING PARTNERS PRIVACY POLICIES</h2>
			<div class="line"></div>
			<p class="context-desc">
				{{ advertising_partners_privacy_policies }}
			</p>

			<h2 class="context-title">THIRD PARTY PRIVACY POLICIES</h2>
			<div class="line"></div>
			<p class="context-desc">
				{{ third_party_privacy_policies }}
			</p>

			<h2 class="context-title">CCPA PRIVACY RIGHTS (DO NOT SELL MY PERSONAL INFORMATION)</h2>
			<div class="line"></div>
			<p class="context-desc">
				{{ ccpa_privacy_rights.startingStatement }}

				<ul class="context-desc ml-4">
					<li v-for="item in ccpa_privacy_rights.listItems" :key="item.id">
						{{ item }}
					</li>
				</ul>

				<span style="font-size: 1.35rem;" v-html="insertHTMLContent(ccpa_privacy_rights.endingStatement, 'contact us', '/contact')"></span>
			</p>

			<h2 class="context-title">GDPR DATA PROTECTION RIGHTS</h2>
			<div class="line"></div>
			<p class="context-desc">
				{{ gpdr_data_protection_rights.startingStatement }}

				<ul class="context-desc ml-4">
					<li v-for="item in gpdr_data_protection_rights.listItems" :key="item.id">
						{{ item }}
					</li>
				</ul>

				<span style="font-size: 1.35rem;" v-html="insertHTMLContent(gpdr_data_protection_rights.endingStatement, 'contact us', '/contact')"></span>
			</p>

			<h2 class="context-title">CHILDREN'S INFORMATION</h2>
			<div class="line"></div>
			<p class="context-desc">
				{{ childrens_information }}
			</p>

		</div>
	</div>
</template>

<script>
import { getPrivacyPolicyContent } from "../../../services/privacyPolicy";
import router from "@/router";
export default {
	data() {
		return {
			consent: "",
			log_files: "",
			advertising_partners_privacy_policies: "",
			third_party_privacy_policies: "",
			childrens_information: "",
			main_statement: [],
			information_we_collect: [],
			how_we_use_information: {},
			cookies_and_web_beacons: [],
			ccpa_privacy_rights: {},
			gpdr_data_protection_rights: {},
		};
	},
	mounted() {
		this.updateLastUpdated();
	},
	methods: {
		openUrl(url){
			const urlRoute = this.$router.resolve({ path: url });
			window.open(urlRoute.href.slice(1), '_blank');
		},
		insertHTMLContent(paragraphText, textToReplace, textUrl, target = "_self"){
			return paragraphText.replace(textToReplace, `<a style="color: #EBBA0A" target="${target}" href="${textUrl}">${textToReplace}</a>`);
		},
		navigateToContactUs() {
			router.push("/contact");
		},
		updateLastUpdated() {
			const {
				main_statement,
				consent,
				information_we_collect,
				how_we_use_information,
				log_files,
				cookies_and_web_beacons,
				advertising_partners_privacy_policies,
				third_party_privacy_policies,
				ccpa_privacy_rights,
				gpdr_data_protection_rights,
				childrens_information,
			} = getPrivacyPolicyContent();

			// The main privacy policy statement 
			this.main_statement = main_statement.map(({ paragraph }) => paragraph);

			// The privacy policy consent
			this.consent = consent;

			// The privacy policy concerning the information that is collected
			this.information_we_collect = information_we_collect.map(({ paragraph }) => paragraph);

			// Privacy policy concerning how information collected is used
			this.how_we_use_information.statement = how_we_use_information.statement;
			this.how_we_use_information.listItems = how_we_use_information.list_items.map(({ item }) => item);

			// Privacy policy about log files
			this.log_files = log_files;

			// Privacy policy regarding cookies and web beacons
			this.cookies_and_web_beacons = cookies_and_web_beacons.map(({ paragraph }) => paragraph);

			// Privacy policy with respect to advertising partners
			this.advertising_partners_privacy_policies = advertising_partners_privacy_policies;

			// Privacy policy with respect to third parties
			this.third_party_privacy_policies = third_party_privacy_policies;

			// Privacy policies with regards to California Consumers
			this.ccpa_privacy_rights.startingStatement = ccpa_privacy_rights.starting_statement;
			this.ccpa_privacy_rights.listItems = ccpa_privacy_rights.list_items.map(({ item }) => item);
			this.ccpa_privacy_rights.endingStatement = ccpa_privacy_rights.ending_statement;

			// Privacy policy with regards to user data protection rights
			this.gpdr_data_protection_rights.startingStatement = gpdr_data_protection_rights.starting_statement;
			this.gpdr_data_protection_rights.listItems = gpdr_data_protection_rights.list_items.map(({ item }) => item);
			this.gpdr_data_protection_rights.endingStatement = gpdr_data_protection_rights.ending_statement;

			// Privacy policy with respect to children's information
			this.childrens_information = childrens_information;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../../views/FrontendPages/CorporateInfoPages/corporate_info.css";

.header-image {
	background-image: url("../../../assets/images/background/corporateinfopage/AdobeStock_292278205@2x.png") !important;
}

.anchor {
	color: #EBBA0A;
}
.anchor:hover {
	cursor: pointer;
	color: #EBBA0A;
}
.anchor::after {
	content:".";
	color: #000;
}

</style>
